<template>
  <!-- 市场活动 -->
  <div class="box">
    <div
      @click="tocli(banner.photo.id, banner.url)"
      class="image wow fadeInUp"
      :style="`background-image:url(${
        banner.photo && $imgurl + banner.photo.url
      })`"
    >
      <!-- <div class="txt">
        <div>{{header.intro}}</div>
        <div>{{header.tags}}</div>
      </div> -->
    </div>
    <div class="sambox">
      <div class="more">
        <span>最新活动</span>
        <!-- <div class="btn">
        <img src="" alt="">
        <router-link to="/bazaarlist" class="morebtn">MORE+</router-link>
      </div> -->
        <div class="btn" @click="tolist()">
          <img :src="img02" alt="" />
          <span>MORE +</span>
        </div>
      </div>
      <div class="cont" v-if="list.length > 0">
        <div
          @click="tocli(item.id, item.url)"
          class="conb wow fadeInUp hover-deepen"
          :data-wow-delay="`${index * 0.3}s`"
          v-for="(item, index) in list"
          :key="index"
        >
          <img :src="item.photo && $imgurl + item.photo.url" alt="" />
          <div class="imgdiv" v-html="item.title"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
let WOW = require("wowjs");
export default {
  data() {
    return {
      banner: {},
      //标题
      header: {},
      //底部图片
      list: [],
      pageNo: "1",
      pageSize: "4",
      list1: [],
      img02: require("@/assets/img01/b1-s2.png"),
    };
  },
  mounted() {
    this.getdatas();
  },
  methods: {
    getdatas() {
      this.$fetch({
        url: "index.php/news/schd",
        type: "post",
        data: this.$qs.stringify({
          pageNo: this.pageNo,
          pageSize: this.pageSize,
        }),
      }).then((res) => {
        console.log(res);
        this.header = res.data.header;
        this.list = res.data.list;
        this.pageNo = res.data.pageNo;
        this.pageSize = res.data.pageSize;
        this.banner = this.list.shift(0);
        this.$nextTick(() => {
          let wow = new WOW.WOW({
            boxClass: "wow",
            animateClass: "animated",
            offset: 0,
            mobile: true,
            live: true,
          }).init();
        });
      });
    },
    //跳转
    tocli(id, url) {
      if (url) {
        console.log(url);
        window.open(url);
      } else {
        this.$router.push({
          path: "/bazaarview",
          query: {
            id,
            crems: JSON.stringify({ title: "市场活动", path: "/news/bazaar" }),
          },
        });
      }
    },
    // 去列表
    tolist(ctype, cid) {
      this.$router.push({
        path: "/bazaarlist",
        query: {
          ctype: ctype,
          cid: cid,
          crems: JSON.stringify({
            title: "市场活动",
            path: "/news/bazaarlist",
          }),
        },
      });
    },
  },
};
</script>

<style scoped>
.box .image {
  width: 1200px;
  height: 526px;
  margin: 0 auto 50px auto;
  background: url("../../assets/img01/news_bg.png");
  cursor: pointer;
  background-size: cover;
}
.box .image {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
}
.box .image .txt div:nth-child(1) {
  font-size: 40px;
  font-weight: bold;
}
.box .image .txt div:nth-child(2) {
  font-size: 32px;
  color: #ffffff;
  margin-top: 36px;
}
.sambox {
  width: 100%;
  /* height: 380px; */
  background: #f6f7fa;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.sambox .cont {
  width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 50px 0 80px 0;
}
.sambox .conb {
  width: 376px;
  height: 250px;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  margin-right: 50px;
}
.sambox .conb:last-child {
  margin-right: 0;
}
.sambox .more {
  display: flex;
  width: 1200px;
  justify-content: space-between;
  align-items: center;
  line-height: 1;
  margin: 40px 0 20px 0;
}
.sambox .more span {
  display: block;
  font-size: 28px;
  color: #333;
}
.sambox .more .btn {
  display: flex;
  align-items: center;
  font-size: 0.72917vw;
  text-align: right;
  color: #396aff;
  font-size: 12px;
  cursor: pointer;
}
.sambox .more .btn img {
  margin: 0 4px 0 0;
}
.sambox .more .btn span {
  font-size: .57292vw;
  color: #396aff;
  font-weight: normal;
}
/* .sambox .conb:hover img {
  transform: scale(1.1);
  transition: all 0.3s linear;
} */
.sambox .conb img {
  width: 100%;
  height: 100%;
  transition: all 0.3s linear;
}
.sambox .imgdiv {
  width: 376px;
  height: 48px;
  line-height: 48px;
  background: linear-gradient(
    327deg,
    rgba(57, 106, 255, 0.33) 0%,
    #396aff 100%
  );
  position: absolute;
  left: 0;
  bottom: 0;
  padding-left: 20px;
  font-size: 16px;
  color: #ffffff;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
@media (min-width: 1280px) {
  .box .image {
    width: 1200px;
    height: 526px;
    margin: 0 auto 50px auto;
    background: url("../../assets/img01/news_bg.png");
    cursor: pointer;
    background-size: cover;
  }
  .box .image {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    text-align: center;
  }
  .sambox .more .btn span {
    font-size: 11px;
    color: #396aff;
  }
  .box .image .txt div:nth-child(1) {
    font-size: 40px;
    font-weight: bold;
  }
  .box .image .txt div:nth-child(2) {
    font-size: 32px;
    color: #ffffff;
    margin-top: 36px;
  }
  .sambox {
    width: 100%;
    /* height: 380px; */
    background: #f6f7fa;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .sambox .more {
    width: 1200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 40px 0 20px 0;
  }
  .sambox .more span {
    font-size: 28px;
    color: #333;
    font-weight: 600;
  }
  .sambox .cont {
    width: 1200px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 50px 0 80px 0;
  }
  .sambox .conb {
    width: 376px;
    height: 250px;
    position: relative;
    cursor: pointer;
    overflow: hidden;
  }
  .sambox .conb img {
    width: 100%;
    height: 100%;
    transition: all 0.3s linear;
  }
  
  .sambox .imgdiv {
    width: 376px;
    height: 48px;
    line-height: 48px;
    background: linear-gradient(
      327deg,
      rgba(57, 106, 255, 0.33) 0%,
      #396aff 100%
    );
    position: absolute;
    left: 0;
    bottom: 0;
    padding-left: 20px;
    font-size: 16px;
    color: #ffffff;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }
}
@media (max-width: 1367px) and (min-width: 1280px) {
  .box .image {
    width: calc(1200px * 0.9);
    height: calc(526px * 0.9);
    margin: 0 auto calc(50px * 0.9) auto;
    background: url("../../assets/img01/news_bg.png");
    cursor: pointer;
    background-size: cover;
  }
  .box .image {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    text-align: center;
  }
  .box .image .txt div:nth-child(1) {
    font-size: 40px;
    font-weight: bold;
  }
  .box .image .txt div:nth-child(2) {
    font-size: 32px;
    color: #ffffff;
    margin-top: calc(36px * 0.9);
  }
  .sambox {
    width: 100%;
    /* height: 380px; */
    background: #f6f7fa;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .sambox .more {
    width: calc(1200px * 0.9);
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: calc(40px * 0.9) 0 calc(20 * 0.9) 0;
  }
  .sambox .more span {
    font-size: 24px;
    color: #333;
    font-weight: 600;
  }
  .sambox .more .btn span {
    font-size: 11px;
    color: #396aff;
  }
  .sambox .cont {
    width: calc(1200px * 0.9);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: calc(50px * 0.9) 0 calc(80px * 0.9) 0;
  }
  .sambox .conb {
    width: calc(376px * 0.9);
    height: calc(250px * 0.9);
    position: relative;
    cursor: pointer;
    overflow: hidden;
  }
  .sambox .conb img {
    width: 100%;
    height: 100%;
    transition: all 0.3s linear;
  }
  .sambox .imgdiv {
    width: calc(376px * 0.9);
    height: calc(48px * 0.9);
    line-height: calc(48px * 0.9);
    background: linear-gradient(
      327deg,
      rgba(57, 106, 255, 0.33) 0%,
      #396aff 100%
    );
    position: absolute;
    left: 0;
    bottom: 0;
    padding-left: calc(20px * 0.9);
    font-size: 16px;
    color: #ffffff;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }
}
</style>